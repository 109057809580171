var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},_vm._l((_vm.ligen),function(l){return _c('base-material-card',{key:l._id,attrs:{"icon":"far fa-trophy-star","title":l.name,"color":"red"}},[_c('v-data-table',{attrs:{"items":l.teams,"headers":[
        { text: 'Platz', value: 'place', sortable: false, align: 'center' },
        { text: 'Mannschaft', value: 'name', sortable: false, align: 'left' },
        { text: 'TP', value: 'result.tp', sortable: false, align: 'center' },
        { text: 'GP', value: 'result.gp', sortable: false, align: 'center' },
        { text: 'WK', value: 'result.wk', sortable: false, align: 'center' }
      ],"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0},on:{"click:row":_vm.open}})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }