<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      v-for="l in ligen"
      icon="far fa-trophy-star"
      :title="l.name"
      color="red"
      :key="l._id"
    >
      <v-data-table
        :items="l.teams"
        :headers="[
          { text: 'Platz', value: 'place', sortable: false, align: 'center' },
          { text: 'Mannschaft', value: 'name', sortable: false, align: 'left' },
          { text: 'TP', value: 'result.tp', sortable: false, align: 'center' },
          { text: 'GP', value: 'result.gp', sortable: false, align: 'center' },
          { text: 'WK', value: 'result.wk', sortable: false, align: 'center' }
        ]"
        hide-default-footer
        :items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="open"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbM2021League {
    name
    shortName
    order
      teams {
        team { _id name }
      }
      events {
        _id
        ... on StbM2021Wk {
          startDate
          teams {
            _team
            result { final score gp tp }
          }
        }
      }
  }
`

export default {
  name: 'tables',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    ligen () {
      return this.EventFind?.map(l => ({
        _id: l._id,
        name: l.name,
        shortName: l.shortName,
        order: l.order,
        teams: l.teams?.map(t => ({
          _id: t.team._id,
          name: t.team.name,
          result: this.results(l._id, t.team._id)
        }))?.sort((a, b) => {
          if (a.result.tp !== b.result.tp) return a.result.tp < b.result.tp ? 1 : -1
          if (a.result.gp !== b.result.gp) return a.result.gp < b.result.gp ? 1 : -1
          return a.result.wk < b.result.wk ? -1 : 1
        })?.map((t, i, arr) => ({
          ...t,
          place: arr.filter((a, j) => j < i && (t.result.tp !== a.result.tp || t.result.gp !== a.result.gp || t.result.wk !== a.result.wk)).length + 1
        })) || []
      }))?.sort((a, b) => {
        return a.order < b.order ? -1 : 1
      }) || []
    }
  },

  methods: {
    results (lid, tid) {
      return this.EventFind?.find(l => l._id === lid)?.events?.filter(e => !!e.teams?.find(t => t._team === tid))?.reduce((acc, curr) => {
        acc.push(curr.teams.find(t => t._team === tid)?.result)
        return acc
      }, []).reduce((acc, curr) => {
        acc.final += curr?.final || 0
        acc.score += curr?.score || 0
        acc.gp += curr?.gp || 0
        acc.tp += curr?.tp || 0
        if (curr && curr.final !== null) acc.wk++
        return acc
      }, { final: 0, score: 0, gp: 0, tp: 0, wk: 0 })
    },
    open (item) {
      this.$router.push(`/e/${this.id}/team/${item._id}`)
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
